<template>
    <div class="m-0 py-2 alert" :class="`alert-${type ?? 'custom'}`" role="alert"
        aria-live="assertive" aria-atomic="true">
        <div class="align-items-center">
            <div class="d-flex justify-content-between align-items-center">
                <div class="toast-body">
                    {{ message }}
                </div>
                <div class="d-flex align-items-center gap-3">
                    <div v-if="!resolved && !rejected" class="spinner-border spinner-border-sm ms-auto" aria-hidden="true">
                    </div>
                    <span v-if="resolved"><i class="bi bi-check h2"></i></span>
                    <span v-if="rejected"><i class="bi bi-x-circle h4"></i></span>
                    <button :style="[ resolved || rejected ? { 'visibility': 'visible' } : { 'visibility': 'hidden' } ]" type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps<{
    message: string,
    promise: Promise<void>
    type?: string
}>();

let resolved = ref(false);
let rejected = ref(false);

props.promise.then(() => {
    resolved.value = true;
}, () => {
    rejected.value = true;
})
</script>
